export const m = {
  contactmode: 'Coontact',
  servicephone: 'Service phone',
  firmaddress: 'Firm address',
  servicenumber: 'Service number',
  miniprograms: 'Applet',
  dock: 'Dock container dynamics',
  orderlie: 'Order message',
  lie: 'Message',
  Sendcomment: 'Send',
  Prompt: 'Please enter the message content',
  Recover: 'Recover',
  dockdesc: 'Dynamic query of terminals at major ports across the country',
  partner: 'Our premium partner',
  partnerdesc: 'Click to jump to the booking page of the shipping company',
  addresstxt: 'Room 2004, Unit 1, No. 30, Longcheng Road, Shibei District, Qingdao City',
  master: 'Master Order',
  divide: 'Divide the order',
  servicecontact: 'Consult',
  placeorder: 'Place order',
  canceltxt: 'Reason for cancellation',
  changebatch: 'Change batch',
  placeconact: 'Place contact',
  required: '* is required',
  dest_address: 'Final destination/contact person/phone number/contact email',
  placephone: 'Place phone',
  placemail: 'Place mail',
  placecode:'Sales code',
  placerecord: 'Plcae record',
  amount: 'Amount',
  contactphone: 'Contact phone',
  license2: 'license',
  namecard: 'Upload business card',
  namecard2: 'Namecard',
  billing: 'Builling',
  registrationnumber: 'Registration number',
  placeinputphone: 'Please enter the contact',
  placeinputmail: 'Please enter your email address',
  addmail:'Add a mailbox',
  mailerror: 'The email format is incorrect, please confirm and try again!',
  submittxt: 'submit',
  authentication: 'User authentication',
  bankaccount: 'Bank account',
  bankcode: 'Bank code(CNY)',
  bankcodeUSD: 'Bank code(USD)',
  // authenticationsuccess: 'Success authentication',
  authenticationsuccess:'Your authentication has been submitted, please be patient and wait for the review',
  orderRemarks:'Order notes',
  authenticationverify: 'Your certification submission is currently at',
  verifyright: '，Please go to Personal Information - User authentication',
  required: 'This field is mandatory',
  shipowner: 'Shipowner',
  divideinfo: 'Contact information of the agent at the port of destination',
  mainladnumber: 'Master bill of lading number',
  otherladnumber: 'Separate bill of lading numbers',
  addcontainers: 'Add a Container',
  deletetemplate:"Delete Template",
  delete:"delete",
  placeinfo: 'Contact information for placing an order',
  export: 'Export',
  edit: 'Edit',
  preview:'preview',
  phoneerror: 'The format of the mobile phone number is incorrect',
  othertxt: 'Other info',
  reviewed: 'To be reviewed',
  passed: 'Passed',
  rviewstatus: 'Rview status',
  haveerror: 'Have error',
  inform: 'The new upgrade of the quotation platform of SPEEDYETA, the addition of online ordering function, the new function is very good to protect your customer information, as long as your real name authentication can be convenient to place an order.',
  girl: "Goods Receiving information",
  door: "CY-DOOR",
  port: "CY-CY",
  carload: "LCL/LFT/FTL",
  date: "Release date",
  nation: "Country of origin",
  haven: "POL",
  place: 'Place an order in the Country',
  model: 'Box type',
  DAP:'DAP',
  DDU:'DDU',
  DDP:'DDP',
  true: 'yes',
  false: 'not',
  placedate: 'Date',
  orderdetail: 'Order detail',
  finaldestination: 'Final destination',
  boxweight: 'Single Container Weight（kg）',
  contact: 'Contact',
  monotype: 'Monotype',
  boat: 'Boat/Voyage',
  boattrips: 'Voyage',
  transportModel:'Mode of transport',
  mileage: 'Mileage',
  otherinfo: 'Other info',
  cancelorder: 'Cancel order',
  submitorder: 'Submit order',
  boxcode: 'Box code',
  take: "Goods receiving information",
  destination: "Country of arival",
  postcode: "Destination zip code",
  week: "POD/Destination",
  address: "Destination address",
  note: "Remarks",
  cargo: "other/information",
  containers: "Container number",
  specification: "Type of container",
  rough: "Gross weight (Per CNTR)",
  add: "Add container",
  read: "I have read and agreed to the",
  agreement: "<Service agreement>",
  inquiry: "Inquire now",
  style: "Address type",
  departure: "Pickup loaction",
  description: "Commodity",
  quantity: "Quantity of goods",
  palletizing: "Number of pallets",
  cardboard: "Packaging type",
  value: "($)Value",
  basis: "Item weight",
  long: "Length",
  wide: "Width",
  high: "Height",
  danger: "Hazmat or Non-Hazmat",
  gross: "Total weight (kg)",
  volume: "Total volume",
  reference: "Query number",
  reference2: "Order number",
  status: "Order status",
  search: "Search",
  reset: "Reset",
  operation: "Operation",
  essential: "Basic information",
  accept: "Accepted offers",
  time: "Publish time",
  cancellation: "Cancel order",
  relevant: "Relevant quote",
  customer: "Exclusive customer servise",
  customerservice: "customer service",
  name: "Customer servise name",
  mail: "Contact email",
  company: "Enterprise information",
  firm: "Company name",
  collect: "collect",
  business: "Type of enterprise",
  includes: "Total cost includes",
  united: "Boost global trade",
  logistics:
    "SPEEDYETA Your global partner SPEEDYETA.COM The free inquiry platform for FCL/LCL to port and door service in more than 100 countries around the world, with the  goal of fast, accurate, and preferential service so you can expand your global business with ease. We protect your logistics!",
  query: "Search",
  overseas: "Ready to expand overseas?",
  global: "Go global easily",
  flow: "We will simplify your logistics and help you enter this world of opportunities without worry. No matter the size of your business, will make your logistics simple.",
  about: "About SPEEDYETA",
  aboutus: "About Us",
  oneself:
    "We are committed to providing customers with high service, high quality and high standards of one-stop standardized intelligent international logistics solutions.",
  understand: "To know more",
  discover: "Discover our end-to-end services",
  transport:
    "Do you want to ship your goods port to port or warehouse to warehouse? SPEEDYETA can help you safely arrange all shipping issues. We offer integrated logistics solutions, from customs clearance services to cargo transportation.",
  todoor: "Delivery service",
  supplement:
    "Door-to-door service is an important supplement to sea transportation, by sending SPEEDYETA, you will get more than 100 teams, thousands of units of capacity to provide you with delivery to the door service, you only need to confirm the need for service on the platform, the rest of all to us to deal with.",
  sea: "Sea freight service",
  process:
    "With nearly 20 base ports and more than 50 inland locations in the United States and Canada, SPEEDYETA offers a full range of services that not only enable you to connect your business globally, but also streamline your end-to-end logistics in the process.",
  clearance: "Customs clearance ...",
  full: "With SPEEDYETA's customs clearance service, you can make your goods more convenient, and you don't have to worry about it. Whether you want to import or export customs clearance, we can provide full service.",
  timely: "Timely Freight rate",
  situation:
    "When the opportunity arises, please grasp firmly and let the market work for you. We pay attention to the market price trend and provide dynamic rates based on the current supply and demand situation.",
  perspectives: "News and perspectives",
  latest: "Latest news: the latest industry news is just a click away!",
  shipment: "First shipment",
  shipping: "Let us help you get ready for shipping",
  rules:
    "The complexity of shipping rules can be overwhelming, and all kinds of information abound. To get started - let us provide you with some guidance for your first shipment.",
  reserved:
    "Fulltrans international logistics (Qingdao) co.,ltd.all rights reserved lu ICP No. 2022040022-1",
  industry: "Trade news",
  details: "Read more",
  platform:
    "Send which interest · Professional overseas transport logistics industry inquiry platform",
  transparent: "Price transparency",
  system: "Perfect logistics system",
  sales: "Professional pre-sales and after-sales service",
  operate:
    "As a professional inquiry platform for the overseas transport and logistics industry, Speedyeta, relies on years of rich industry experience and strong network resources, taking accurate industry data as the benchmark, and achieving at efficient and professional services . Through online real-time quotation, intelligent matching and other mechanisms, Speedyeta meets the global logistics customers' demand for overseas inquiry . At present, it has established long-term cooperative relations with thousands of overseas logistics enterprises in China and around the world. Motivated by continuous innovation and development, Speedyeta is providing more and more customers with the ability to go to sea with professional, efficient, and high-quality overseas transportation services.",
  officially:
    "Speedyeta is the overseas logistics e-commerce platform of Fulltrans Global International Logistics (Qingdao) Co., LTD.  Speedyeta was officially launched in January 2022.  With a keen grasp of the rapid development of the global trade market and the changing trends of customer demand, relying on strong and stable core resources and excellent quality, and aiming at efficient and professional service capabilities, Speedyeta provides one-stop overseas freight services for global trade customers through online real-time quotes, intelligent matching, and other mechanisms.",
  guidance:
    "The complexity of shipping rules can be overwhelming, and all kinds of information abound. To get started - let us provide you with some guidance for your first shipment.",
  site: "Address",
  phone: "Tel",
  facsimile: "Fax",
  shanghai: "Shanghai",
  building:
    "ROOM 1901, BEIMEI PLAZA TOWER A, NO.518 KUNMING ROAD, YANGPU DISTRICT, SHANGHAI, CHINA, 200082 TEL:+86(21)5186-7233; FAX:+86(21)5186-7289",
  qingdao: "Qingdao",
  road: "RM 2004, UNIT 1,NO.30, LONGCHENG ROAD, SHIBEI DISTRICT,QINGDAO 266034",
  nanjing: "Nanjing",
  pukou:
    "Room 218, No.15, Zhixin Tech Tower, Xinghuo Road, Pukou District, Nanjing City, Jiangsu Province, PRC",
  lianyungang: "Lianyungang",
  block:
    "RM 1601,UNIT A, ZHONGHUA HAOTING MANSION, 80 ZHONGHUA ROAD(W), LIANYUN DISTRICT, LIANYUNGANG, CHINA 222042",
  ningbo: "Ningbo",
  west: "Room 1309, No. 2 Zhongshan West Road, Haishu District, Ningbo City, Zhejiang Province",
  tianjin: "Tianjin",
  heping:
    "2504, BUILDING 1, XIAWAFANG, JINGCAI BUILDING, HEXI DISTRICT, TIANJIN CHINA. 300202",
  zhangjiagang: "Zhangjiagang",
  renmin:
    "Room 509 Jincheng Mansion,NO.49 Renmin Road,Yangshe Town,Zhangjiagang City,Jiangsu, China 215600",
  changzhou: "ChangZhou",
  taihu:
    "ROOM 5-2508,CHANGFA COMMERCIAL PLAZA,NO.101-1,TAIHU EAST ROAD,XINBEI DISTRICT,CHANGZHOU,JIANGSU,CHINA",
  reason: "Cancellation reason",
  cancel: "Cancel",
  verify: "Confirm",
  standard: "Additional costs",
  cost: "Total cost",
  fleet: "Trucker name",
  type: "Trucker type",
  freight: "Total freight",
  controls: "Operation",
  information: "Trucker information",
  offer: "Accept offer",
  finally: "Final quote",
  enquiry: "Inquiry center",
  safety: "Security center",
  account: "Account information",
  head: "Personal profile image",
  personage: "Personal information",
  mailbox: "Email",
  call: "Phone number",
  amend: "Modify",
  save: "Save",
  mobile: "Modify phone number",
  auth: "Verification code",
  security: "Get verification code",
  next: "Next",
  sent: "Sent(",
  bound: "Bind a new phone number",
  ensure: "Confirm",
  standing: "Identity authentication",
  attempts: "Reset password",
  again: "Confirm again",
  once: "log in password",
  friends: "Invite friends",
  bids: "Invitation searchrecord",
  link: "Invitation link",
  copy: "Copy",
  register: "Registration time",
  pet: "Username",
  rebate: "Invitation bonus",
  fyhelper: "Bonus searchrecord",
  grand: "Bonus accumulation",
  remaining: "Balance of account",
  promptly: "Cash out now",
  buxify: "Bonus time",
  earnings: "Amount of bouns",
  apply: "Cash out application",
  how: "Note:",
  know: "If you do not know how to operate an overseas account, please contact the platform",
  bank: "Bank account",
  cardholder: "Name of cardholder",
  deposit: "Bank name (CNY)",
  depositUSD: "Bank name (USD)",
  withdrawal: "Cash out amount",
  may: "The amount that can be cashed out",
  approve: "Cash out details",
  need: "time of cash out",
  completeRegistration: "complete registration",
  companyinfo: "Fill in enterprise information",
  telephone: "Service",
  telephone1: "Service",
  loadmore: "load more",
  terrace: "Platform protocol",
  paas: "User agreement",
  service: "Service agreement",
  hint: "Tips",
  want: "Are you sure you want to log out?",
  picture: "Cargo Pictures",
  salaries: "Adding shipments",
  license: "Upload business license",
  electronic: "Upload electronic protocol",
  balance: "Balance cashing out",
  change: "Change password",
  searchrecord: "Inquiry record",
  condition: "Cash out status",
  examine: "Check",
  rejection: "Reason for rejection",
  orderList: "Order list",
  shown: "Order details",
  buying: "Cancellation time",
  vehicle: "FTL trucker",
  forwarder: "FORWARDING/LTL/FTL",
  enroll: "sign in",
  ringup: "Log in",
  homepage: "Home page",
  workbench: "Dashboard",
  provider: "Supplier registration",
  airline: "Quote details",
  cannot:
    "You cannot view or accept other offers after accepting the rate, are you sure you still want to accept this offer?",
  use: "Use our servise",
  interesting: "Contact SPEEDYETA",
  freefone: "Free toll",
  kefuaobicom: "Email",
  create: "Register an account",
  RegistrationTip:'Agent registration needs to be verified by the platform, please contact the platform customer service to confirm the operation.',
  password: "Confirm password",
  away: "Register now",
  agency: "Registration type",
  individuals: "Enterprises and individuals",
  agent: "Agent",
  young: "Have an account?",
  database: "Log in now",
  find: "Forgot password",
  welcome: "Welcome to",
  proceed: "SPEEDYETA pricing inquiry system",
  yet: "Don't have an account yet?",
  when: "Sign up!",
  already: "User account",
  asked: "<User agreement>",
  forget: "Forgot password",
  navigate: "Main page",
  connect: "Contact us",
  yesterday: "Check order details",
  logDown: "To quit",
  pending: "To be processed",
  quotation: "Pending quote",
  confirmed: "To be confirmed",
  underway: "In process",
  settle: "Pending Check out",
  stocks: "Completed",
  canceled: "Cancelled",
  players: "Choose date and time",
  keli: "Please select",
  give: "PLease enter",
  solstice: "To",
  start: "Starting time",
  deadline: "End time",
  reasonfor: "Please enter the reason for cancellation",
  cny_enterAccount:'Please enter bank card account(CNY)',
  usd_enterAccount:'Please enter bank card account(USD)',
  enterCard: "Please enter cardholder name",
  enterMoney: "Please enter the withdrawal amount",
  enterBank: "Please enter the name of the bank where the account is opened",
  enterPass: "Please enter your password",
  enterPhone: "Please enter phone number",
  enterCode: "please enter verification code",
  twice: "Please enter your password twice",
  repetition: "Cannot be obtained repeatedly",
  enterContent: "Please enter content",
  enterstart: "Please select a release date",
  enternation: "Please select the country of shipment",
  enterport: "Please select the port of departure",
  entergoal: "Please select the destination country",
  enterzip: "Please select destination postal code",
  enterpostal: "Please select the destination port/destination",
  enterSite: "Please enter destination address",
  entercontainer: "Please enter the number of containers",
  enterspec: "Please select container specification",
  entergross: "Please enter gross weight",
  enterunit: "Please select a unit of measurement",
  enterread: 'Please agree and read the "Service Agreement" first',
  submit: "Submitted successfully",
  enterName: "Please enter the name of the goods",
  enterNum: "Please enter the quantity of goods",
  enterpalletizing: "Please enter the pallet quantity",
  enterpack: "Please select packaging type",
  entervalue: "Please enter the value",
  tailorism: "Please enter the weight of the item",
  enterlong: "Please enter the length",
  enterwide: "Please enter width",
  enterhight: "Please enter height",
  enterrisk: "Please select whether it is a dangerous item",
  enterfirm: "Please return to the previous page to enter the company name",
  entermail: "Please return to the previous page to enter your email address",
  successfully: "Registration success",
  enterbusiness: "Please enter company name",
  enteremail: "Please enter your email address",
  enterlogin: "Login successful",
  review: "Under review",
  withdrawn: "Withdrawn",
  rejected: "Dismissed",
  funds: "Insufficient balance",
  cash: "Withdraw successfully",
  utton: "Reset successful",
  changed: "Password has been updated",
  preservation: "Saved successfully",
  federal: "The size of the uploaded avatar image cannot exceed 2MB",
  brushing: "Copied",
  templatename:"Template Name",
  gives: "Binding successful",
  deletable: "Cannot be deleted",
  initial: "Please select address type",
  yonghu: "Please enter the origin address",
  accepted: "Accepted",
  pin: "Please enter password",
  back: "Back",
  USA: "USA",
  Canada: "Canada",
  masterplate:"masterplate",
  Destination: "Destination",
  enttemplatename:"Please enter the template name",
  Country: "Country",
  addTemplate:"Add Template",
  WeChat: "WeChat",
  price:"Total trailer fee",
  shipowner_id:"Ship owner ID",
  mytemplate:'My template',
  templatestatus:"Template status",
  templatename:"Template Name",
  templateRecord:"Template modification",
  nowprice: "Real-time quotation",
  onlineprice: "Online quotation",
  searchrecord: "Inquiry record",
  rengongprice: "Manual quotation",
  myorder: "Order Record",
  continuetext:
    "About to jump to the order inquiry address, do you want to continue?",
  hometext1:
    "You focus on growing your business in the United States and Canada and we guarantee your destination in-land services",
  hometext2:
    "The business world is changing and the time is right to change the way business is done. Move your logistics services from offline to online",
  hometext3:
    "With SPEEDYETA, you can easily inquire, track and arrange your goods online",
  searchcarnow: "Inquiry real-time trucking rates",
  startcity: "POL (zip code)",
  startonecity: "POL",
  endonecity: "POD",
  endcity: "POD (zip code)",
  endport: "POD/destination address",
  getnowprice: "Get real-time rates",
  selectstartcity: "Please select POL",
  selectendCity: "Please select POD",
  selectendport: "Please select POD/destination address",
  last: "Previous",
  nextone: "Next",
  newnew: "Latest News",
  hotnew: "Popular News",
  nomorelast: "No more",
  nomorenext: "No more",
  earn: "Charging standard",
  allprice: "Trucking Charges Summation",
  oneendport: "POD",
  carmi: "Trucking Miles",
  searchhistory: "Inquiry record",
  searchnowprice: "Inquiry real-time rates",
  searchtime: "Date",
  addTemplates: 'Add Templates',
  editTemplate: 'Edit Templates',
  Save: 'Save or not',
  selecttemplate: 'Please select online template',
  titype: "Type of Picking up",
  ticode: "The zip code of picking address",
  selecttutype: "Please select the type of Picking up",
  selectsongtype: "Please select the type of delivery",
  inputticode: "Please enter Picking up Zip code",
  songtype: "Type of delivery",
  addgoodsinfo: "Please add cargo information",
  carteamprice: "Trucker’s quotation",
  acceptprice: "Trucker’s quotation accepted",
  orderinfo: "Order information",
  markcontent: "Content of remarks",
  cancelsucess: "Canceled successfully",
  yingyeagreement: "Business agreement",
  acpass: "Account Password",
  balancedec: "Balance detail",
  binded: "Linked successfully:",
  laststep: "Previous Step",
  changephone: "Cell phone number successfully modified",
  message: 'News',
  inform: 'The new upgrade of the quotation platform, the addition of online ordering function, the new function is very good to protect your customer information, as long as your real name authentication can be convenient to place an order.',

  
  noworderhint: {
    // 基本信息
    dest_address: 'Please enter the final destination address/contact/phone/email address',
    // 下单联系人信息
    order_username: 'Please enter the contact person to place your order',
    order_phone: 'Please enter the phone number of the contact person to place the order',
    order_email: 'Please enter the email address of the contact person who placed the order',
    blno: 'Please enter the bill of lading number',
    // ship_name: 'Please enter the name of the vessel',
    mawb_blno: 'Please enter the master bill number',
    vessel_voyage: 'Please enter the boat number',
    shipowner_id: 'Please enter the shipowner',
  },
  uppaddinghint: {
    // 基本信息
    template_name: 'Please enter the template name',
    dest_address: 'Please enter the final destination address/contact/phone/email address',
    // 下单委托人信息
    order_username: 'Please enter the contact person to place your order',
    order_phone: 'Please enter the phone number of the contact person to place the order',
    order_email: 'Please enter the email address of the contact person who placed the order',
},
  placeerror: 'The email address of the contact person who placed the order is in the wrong format, please confirm and try again!',
  placecasesgaugetype: 'Please select the box gauge',
  placeboxno: 'Please enter the box number',
  placeweight: 'Please enter the weight of a single box',
};
