import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/index",
    component: Layout,
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "about",
        name: "about",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "关于送哪趣",
        },
      },
      {
        path: "information",
        name: "information",
        component: () => import("@/views/information/index.vue"),
        meta: {
          title: "行业资讯",
          showFather: true,
        },
        children: [
          {
            path: "informationDec",
            name: "informationDec",
            component: () => import("@/views/information/informationDec.vue"),
            meta: {
              title: "资讯详情",
              showFather: false,
            },
          },
        ],
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("@/views/contact/index.vue"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "onlineQuotation",
        name: "onlineQuotation",
        component: () =>
          import("@/views/realtimeQuotation/onlineQuotation.vue"),
        meta: {
          title: "在线报价",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "homemyorder",
        name: "homemyorder",
        component: () => import("@/views/home/components/myorder.vue"),
        meta: {
          title: "我的订单",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      // 模版记录
      {
        path: "templateRecord",
        name: "templateRecord",
        component: () => import("@/views/home/components/templateRecord.vue"),
        meta: {
          title: "我的模版",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      // 新增模版
      {
        path: "upaddTemplate",
        name: "upaddTemplate",
        component: () => import("@/views/home/components/upaddTemplate.vue"),
        meta: {
          title: "新增模版",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      // 编辑模版
      {
        path: "templateediting",
        name: "templateediting",
        component: () => import("@/views/home/components/templateediting.vue"),
        meta: {
          title: "编辑模版",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "orderdetail",
        name: "orderdetail",
        component: () => import("@/views/home/components/orderdetail.vue"),
        meta: {
          title: "订单详情",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "email_orderdetail",
        name: "email_orderdetail",
        component: () => import("@/views/home/components/email_orderdetail.vue"),
        meta: {
          title: "订单详情",
        },
      },
      {
        path: "templatemodification",
        name: "templatemodification",
        component: () => import("@/views/home/components/templatemodification.vue"),
        meta: {
          title: "模版详情",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "searchRecord",
        name: "searchRecord",
        component: () => import("@/views/realtimeQuotation/searchRecord.vue"),
        meta: {
          title: "查询记录",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "search",
        name: "search",
        component: () => import("@/views/manualQuotation/search.vue"),
        meta: {
          title: "立即询价",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "searchDec",
        name: "searchDec",
        component: () => import("@/views/manualQuotation/searchDec.vue"),
        meta: {
          title: "立即询价",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "orderDec",
        name: "orderDec",
        component: () => import("@/views/manualQuotation/orderDec.vue"),
        meta: {
          title: "报价详情",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "myorder",
        name: "myorder",
        component: () => import("@/views/manualQuotation/myorder.vue"),
        meta: {
          title: "我的订单",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
      {
        path: "userinfo",
        name: "userinfo",
        component: () => import("@/views/user/index.vue"),
        meta: {
          title: "个人中心",
        },
        beforeEnter: (to, from, next) => {
          // 拦截逻辑
          if (sessionStorage.getItem('user_id')) {
            next();
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
