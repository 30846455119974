<template>
  <div
    :class="
      isHover == true ||
      isHover1 == true ||
      $route.path == '/onlineQuotation' ||
      $route.path == '/searchRecord' ||
      $route.path == '/search' ||
      $route.path == '/searchDec' ||
      $route.path == '/orderDec' ||
      $route.path == '/myorder' ||
      $route.path == '/userinfo'
        ? 'nativecontainer'
        : 'container'
    "
    @mouseleave="
      isHover = false;
      isHover1 = false;
      isHover2 = false;
      
    "
  >
    <!-- <div
    class="nativecontainer"
    @mouseleave="
      isHover = false;
      isHover1 = false;
    "
  > -->
    <div
      :class="
        isHover == true ||
        isHover1 == true ||
        isHover2 == true ||
        $route.path == '/onlineQuotation' ||
        $route.path == '/searchRecord' ||
        $route.path == '/search' ||
        $route.path == '/searchDec' ||
        $route.path == '/orderDec' ||
        $route.path == '/myorder' ||
        $route.path == '/about' ||
        $route.path == '/contact' ||
        $route.path == '/userinfo'
          ? 'nativeheader'
          : 'header'
      "
    >
      <div
        :class="
          isHover == true ||
          isHover1 == true ||
          isHover2 == true ||
          $route.path == '/onlineQuotation' ||
          $route.path == '/searchRecord' ||
          $route.path == '/search' ||
          $route.path == '/searchDec' ||
          $route.path == '/orderDec' ||
          $route.path == '/myorder' ||
          $route.path == '/userinfo'
            ? 'nativemainnavbar'
            : 'mainnavbar'
        "
      >
        <!-- <img
          src="@/assets/LOGO-b.png"
          alt=""
          v-if="
            isHover == true ||
            isHover1 == true ||
            show == true ||
            show1 == true ||
            $route.path == '/onlineQuotation' ||
            $route.path == '/searchRecord' ||
            $route.path == '/search' ||
            $route.path == '/searchDec' ||
            $route.path == '/orderDec' ||
            $route.path == '/myorder' ||
            $route.path == '/userinfo'
          "
        />
        <img src="@/assets/LOGO.png" alt="" v-else /> -->
        <div
          class="language"
          @mouseenter="
            isHover = false;
            isHover1 = false;
            isHover2 = false;
          "
          @click="show1 = !show1"
        >
          <span v-if="language == 1">简体中文</span>
          <span v-if="language == 2">EN</span>
          <img src="@/assets/chevron-down.png" alt="" />
          <div class="dropdownbox" v-show="show1">
            <div
              :class="language == 1 ? 'diropdownitem' : 'nodiropdownitem'"
              @click="chineseClick"
            >
              简体中文
            </div>
            <div
              :class="language == 2 ? 'diropdownitem' : 'nodiropdownitem'"
              @click="engClick"
            >
              EN
            </div>
          </div>
        </div>
        <div class="navbar">
          <ul>
            <!-- <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
              "
            >
              <router-link class="normal" to="/index">{{
                cutList.homepage
              }}</router-link>
            </li> -->
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
            >
              <router-link class="normal" to="/index">{{
                cutList.onlineprice
              }}</router-link>
            </li>
            <!-- 模版记录 -->
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
            >
              <router-link 
              class="normal" 
              to="/templateRecord"
              @click.native="sendDataToParent"
              >{{cutList.mytemplate}}</router-link>
            </li>
            <!-- 下单记录 -->
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
            >
              <router-link 
                class="normal" 
                to="/homemyorder"
                @click.native="sendDataToParent"
              >{{cutList.placerecord}}</router-link>
            </li>
            <!-- 询价记录 -->
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
            >
              <router-link 
                class="normal" 
                to="/searchRecord"
                @click.native="sendDataToParent"
                >{{cutList.searchrecord}}</router-link>
            </li>
            <!-- 人工报价 -->
            <li @mouseenter="searchenter">
              <router-link
                class="normal"
                to="/search"
                @click.native="sendDataToParent"
                >{{ cutList.rengongprice }}</router-link
              >
              <div v-show="isHover1" class="subnav">
                <div class="minsubnav">
                  <router-link
                    class="minsubnavbox normal"
                    to="/search"
                    @click.native="sendDataToParent"
                    >{{ cutList.inquiry }}</router-link
                  >
                  <router-link
                    class="minsubnavbox normal"
                    to="/myorder"
                    @click.native="sendDataToParent"
                    >{{ cutList.myorder }}</router-link
                  >
                </div>
              </div>
            </li>
            <!-- <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
              @click="tosearch"
            >
              {{ cutList.yesterday }}
            </li> -->
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
            >
              <router-link class="normal" to="/information">{{
                cutList.industry
              }}</router-link>
            </li>
            <li @mouseenter="aboutenter">
              <router-link class="normal" to="/about">{{
                cutList.aboutus
              }}</router-link>
              <div v-show="isHover2" class="subnav">
                <div class="minsubnav">
                  <router-link class="minsubnavbox normal" to="/about">{{
                    cutList.about
                  }}</router-link>
                  <router-link class="minsubnavbox normal" to="/contact">{{
                    cutList.connect
                  }}</router-link>
                </div>
              </div>
            </li>
            <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                isHover2 = false;
              "
              @click="addToFavorites"
            >
              {{ cutList.collect }}
            </li>
            <!-- <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
              "
            >
              <router-link class="normal" to="/contact">{{
                cutList.connect
              }}</router-link>
            </li> -->
            <!-- <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
              "
            >
              丨
            </li> -->
            <!-- <li
              @mouseenter="
                isHover = false;
                isHover1 = false;
                show1 = true;
              "
              @mouseleave="show1 = false"
            >
              <span v-if="language == 1">简体中文</span>
              <span v-if="language == 2">EN</span>
              <img
                src="@/assets/chevron-down-b.png"
                alt=""
                v-if="
                  isHover == true ||
                  isHover1 == true ||
                  show == true ||
                  show1 == true ||
                  $route.path == '/onlineQuotation' ||
                  $route.path == '/searchRecord' ||
                  $route.path == '/search' ||
                  $route.path == '/searchDec' ||
                  $route.path == '/orderDec' ||
                  $route.path == '/myorder' ||
                  $route.path == '/userinfo'
                "
              />
              <img src="@/assets/chevron-down.png" alt="" v-else />
              <div class="dropdownbox" v-show="show1">
                <div
                  :class="language == 1 ? 'diropdownitem' : 'nodiropdownitem'"
                  @click="chineseClick"
                >
                  简体中文
                </div>
                <div
                  :class="language == 2 ? 'diropdownitem' : 'nodiropdownitem'"
                  @click="engClick"
                >
                  EN
                </div>
              </div>
            </li> -->
            <!-- <li @mouseenter="inenteruser" @mouseleave="show = false">
              <img
                src="@/assets/avator-b.png"
                alt=""
                @click="sendDataToParent"
                v-if="
                  isHover == true ||
                  isHover1 == true ||
                  show == true ||
                  show1 == true ||
                  $route.path == '/onlineQuotation' ||
                  $route.path == '/searchRecord' ||
                  $route.path == '/search' ||
                  $route.path == '/searchDec' ||
                  $route.path == '/orderDec' ||
                  $route.path == '/myorder' ||
                  $route.path == '/userinfo'
                "
              /><img
                src="@/assets/avator.png"
                alt=""
                v-else
                @click="sendDataToParent"
              />
              <div class="dropdownbox" v-show="show">
                <div class="nodiropdownitem" @click="touserinfo">
                  {{ cutList.personage }}
                </div>
                <div class="nodiropdownitem" @click="logout">
                  {{ cutList.logDown }}
                </div>
              </div>
            </li> -->
          </ul>
        </div>
        <div class="btnbox" v-if="user_id == ''">
          <div class="enrollbtn" @click="sendData">{{ cutList.enroll }}</div>
          <div class="loginbtn" @click="sendDataToParent">
            {{ cutList.ringup }}
          </div>
        </div>
        <div
          class="userbox"
          v-else
          @mouseenter="inenteruser"
          @mouseleave="show = false"
        >
          <div class="nameimgbox">
            <img :src="avator" alt="" />
            <div class="username">{{ name }}</div>
          </div>
          <div class="dropdownbox" v-show="show">
            <div class="nodiropdownitem" @click="touserinfo">
              {{ cutList.personage }}
            </div>
            <div class="nodiropdownitem" @click="logout">
              {{ cutList.logDown }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isHover: false,
      isHover1: false,
      isHover2: false,
      show: false,
      show1: false,
      user_id: "",
      language: 1,
      avator: "",
      name: "",
      hasRedirected: false, // 标志位，用来确保只跳转一次
    };
  },
  computed: {
    cutList() {
      return this.$t("m");
    },
  },
  created() {
    if (this.$route.path == "/search" || this.$route.path == "/myorder") {
      this.isHover1 = false;
    }
    if (this.$route.path == "/about" || this.$route.path == "/contact") {
      this.isHover2 = false;
    }
  },
  mounted() {
    if (sessionStorage.getItem("user_id")) {
      this.user_id = sessionStorage.getItem("user_id");
    }
    if (sessionStorage.getItem("user_img")) {
      this.avator = sessionStorage.getItem("user_img");
    }
    if (sessionStorage.getItem("nickname")) {
      this.name = sessionStorage.getItem("nickname");
    }
    if (sessionStorage.getItem("cut")) {
      if (sessionStorage.getItem("cut") == "zh-CN") {
        this.language = 1;
      } else if (sessionStorage.getItem("cut") == "en-US") {
        this.language = 2;
      }
    }
    // 检查是否已提示过
    if (!localStorage.getItem('hasShownUpgradeMessage')) {
      this.checkUserId();
    }
  },
  watch: {
    user_id() {
      this.checkUserId(); // 监听 user_id 的变化
    }
  },
  methods: {
    // 首页提示信息
    // openHTML() {
    //   this.$message({
    //     dangerouslyUseHTMLString: true,
    //     message: '<strong style="color: red; font-size: 14px; line-height: 22px;">' + this.cutList.inform + '</strong>',
    //     type: 'warning',
    //     duration: 10000,
    //     offset: 60,
    //   });
    // },
    // 首页提示信息弹出标记
    // checkUserId() {
    //   if (this.user_id && !this.hasRedirected) {
    //     this.hasRedirected = true; // 标记为已弹出
    //     this.openHTML();
    //     // 将提示状态保存到 localStorage，防止下次再弹出
    //     localStorage.setItem('hasShownUpgradeMessage', 'true');
    //   }
    // },
    searchenter() {
      if (this.$route.path != "/search" && this.$route.path != "/myorder") {
        this.isHover1 = true;
        this.isHover = false;
        this.isHover2 = false;
      } else {
        this.isHover1 = false;
        this.isHover = false;
        this.isHover2 = false;
      }
    },
    aboutenter() {
      if (this.$route.path != "/about" && this.$route.path != "/contact") {
        this.isHover1 = false;
        this.isHover = false;
        this.isHover2 = true;
      } else {
        this.isHover1 = false;
        this.isHover = false;
        this.isHover2 = false;
      }
    },
    sendDataToParent() {
      console.log("触发sendDataToParent")
      if (this.user_id == "") {
        this.$emit("showstatus", [true, 1]);
        console.log("222")
      }
    },
    sendData() {
      if (this.user_id == "") {
        this.$emit("showstatus", [true, 2]);
      }
    },
    tosearch() {
      this.$confirm(this.cutList.continuetext, this.cutList.hint, {
        confirmButtonText: this.cutList.ensure,
        cancelButtonText: this.cutList.cancel,
        type: "warning",
      })
        .then(() => {
          window.location.href = "http://www.fulltransglobal.com/";
        })
        .catch(() => {});
    },
    touserinfo() {
      this.$router.push("/userinfo");
    },
    logout() {
      if (sessionStorage.getItem("user_id")) {
        sessionStorage.removeItem("user_id");
        
        this.$router.push("/");
        // setTimeout(()=>{
        location.reload();
        // },300)
        
      }
    },
    inenteruser() {
      this.isHover = false;
      this.isHover1 = false;
      if (sessionStorage.getItem("user_id")) {
        this.user_id = sessionStorage.getItem("user_id");
      } else {
        this.user_id = "";
      }
      if (this.user_id != "") {
        this.show = true;
      }
    },
    chineseClick() {
      this.language = 1;
      this.$i18n.locale = "zh-CN"; //关键语句
      sessionStorage.setItem("cut", "zh-CN"); //英文
      this.$router.go(0);
    },
    engClick() {
      this.language = 2;
      this.$i18n.locale = "en-US"; //关键语句
      sessionStorage.setItem("cut", "en-US"); //中文
      this.$router.go(0);
    },
    // 收藏
    addToFavorites() {
      const url = window.location.href; // 当前页面的URL
      const title = document.title; // 当前页面的标题
      const newUrl = this.getEncodedUrl(url, title);

      if (window.sidebar) {
        // 针对IE浏览器的特定代码
        window.sidebar.addPanel(title, newUrl, "");
      } else if (window.external && window.external.AddFavorite) {
        console.log(window.external, "window.external");
        // 针对IE以外的浏览器
        // 注意：window.external.AddFavorite只在IE下有效
        window.external.AddFavorite(newUrl, title);
      } else if (window.opera && window.print) {
        // 针对Opera浏览器的特定代码
        this.createBookmarkLet(title, newUrl);
      } else {
        // 通用的添加收藏的方法
        if (this.language == 1) {
          alert("按 Ctrl+D 将此页添加到收藏夹");
        } else {
          alert("Press Ctrl+D to add this page to your favorites");
        }
      }
    },
    getEncodedUrl(url, title) {
      return encodeURIComponent(url) + "|" + encodeURIComponent(title);
    },
    createBookmarkLet(title, url) {
      window.location.href = `javascript:void(0);`;
      const wsl = window.screenLeft;
      const wst = window.screenTop;
      const wsw = window.outerWidth;
      const wsh = window.outerHeight;
      const popUp = window.open(
        "",
        title,
        `toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=yes,width=500,height=350,left=${
          wsl + (wsw - 500) / 2
        },top=${wst + (wsh - 350) / 2}`
      );
      if (!popUp) return; // 如果弹窗被阻止，则直接返回
      popUp.document.title = title;
      popUp.document.body.innerHTML = `<a href="javascript:void(0);" onclick="window.opener.opera.links['favorite'].addFavorite('${url}','${title}');close();">Add to favorites</a>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.nativecontainer {
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 99;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 99;
  background: #ffffff;
}
.nativeheader {
  width: 100%;
  .mainnavbar {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    align-items: center;
    padding-bottom: 30px;
    box-sizing: border-box;
    width: 1800px;
    margin: auto;
  }

  .nativemainnavbar {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    align-items: center;
    padding-bottom: 30px;
    box-sizing: border-box;
    width: 1800px;
    margin: auto;
  }

  img {
    width: 234px;
    height: 58px;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .navbar li {
    display: inline;
    margin-right: 48px;
    color: #004868;
    font-size: 20px;
    font-weight: 900;
    router-link {
      cursor: pointer;
    }
    .normal:hover {
      color: #ff7214;
      cursor: pointer;
    }
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
  .navbar li:nth-child(10) {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .navbar a {
    text-decoration: none;
    color: #004868;
  }

  .navbar a.router-link-exact-active {
    border-bottom: 1px solid #ff7214;
    padding-bottom: 18px;
    box-sizing: border-box;
    color: #ff7214;
  }
  .navbar {
    display: flex;
  }
  .btnbox {
    display: flex;
    align-items: center;
    .enrollbtn {
      width: 80px;
      height: 34px;
      background: #cccccc;
      border-radius: 0px 0px 0px 0px;
      font-size: 16px;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
      margin-right: 8px;
    }
    .loginbtn {
      width: 80px;
      height: 34px;
      background: #ff6600;
      border-radius: 0px 0px 0px 0px;
      font-size: 16px;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
    }
  }

  .userbox {
    cursor: pointer;
    position: relative;
    .nameimgbox {
      display: flex;
      align-items: center;
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .username {
      font-size: 20px;
      font-weight: 900;
      color: #004868;
    }
  }
}
.language {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  span {
    font-size: 20px;
    font-weight: 900;
    color: #004868;
  }
  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}
.header {
  .navbar {
    display: flex;
  }
  .btnbox {
    display: flex;
    align-items: center;
    .enrollbtn {
      width: 80px;
      height: 34px;
      background: #cccccc;
      border-radius: 0px 0px 0px 0px;
      font-size: 16px;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
      margin-right: 8px;
    }
    .loginbtn {
      width: 80px;
      height: 34px;
      background: #ff6600;
      border-radius: 0px 0px 0px 0px;
      font-size: 16px;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
      cursor: pointer;
    }
  }
  .mainnavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    box-sizing: border-box;
    padding-bottom: 30px;
    width: 1800px;
    margin: auto;
  }

  .nativemainnavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1800px;
    margin: auto;
  }

  img {
    width: 234px;
    height: 58px;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .navbar li {
    display: inline;
    margin-right: 48px;
    box-sizing: border-box;
    color: #004868;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    router-link {
      cursor: pointer;
    }
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
  .navbar li:nth-child(9) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .navbar a {
    text-decoration: none;
    color: #004868;
  }
  .normal:hover {
    color: #ff7214;
    cursor: pointer;
  }
  .navbar a.router-link-active {
    border-bottom: 1px solid #ff7214;
    padding-bottom: 33px;
    box-sizing: border-box;
    color: #ff7214;
  }
  .language {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .userbox {
    cursor: pointer;
    position: relative;
    .nameimgbox {
      display: flex;
      align-items: center;
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .username {
      font-size: 20px;
      font-weight: 900;
      color: #004868;
    }
  }
}

.subnav {
  background: #ffffff;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 80px;
  animation: secondary-menu-show 0.2s ease-out both;
  padding-top: 20px;
  // padding-bottom: 20px;
  box-sizing: border-box;
  background: #ffffff;
  border-top: 1px solid #eeeeee;
  .minsubnav {
    width: 1800px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    .minsubnavbox {
      cursor: pointer;
      margin-right: 32px;
      padding-bottom: 21px !important;
      box-sizing: border-box;
    }
  }
}
.dropdownbox {
  width: 100px;
  height: 94px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 4px 4px;
  padding: 4px;
  box-sizing: border-box;
  z-index: 99;
  position: absolute;
  bottom: -94px;
  left: 32px;
  .diropdownitem {
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    background-color: #ff7214;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    border-radius: 4px 4px 4px 4px;
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 文本溢出容器时隐藏 */
    text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本 */
  }
  .nodiropdownitem {
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    color: #004868;
    text-align: center;
    line-height: 40px;
    border-radius: 4px 4px 4px 4px;
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 文本溢出容器时隐藏 */
    text-overflow: ellipsis; /* 使用省略号表示被隐藏的文本 */
  }
  .nodiropdownitem:hover {
    background-color: #ff7214;
    color: #ffffff;
  }
}
li {
  cursor: pointer;
}
</style>
